<template>
  <ValidationProvider
    :rules="rules == 'none' ? '' : rules"
    v-slot="{ errors, valid }"
  >
    <b-field
      :label="label"
      :message="errors"
      :custom-class="labelClass"
      :class="{ 'flex-row-reverse': select }"
      :type="{ 'is-danger': errors[0] }"
    >
      <div class="control is-clearfix w-2/3" v-if="select">
        <currency-input
          :id="idinput"
          :placeholder="placeholder"
          v-model="innerValue"
          currency="USD"
          locale="en"
          class="input"
          :class="{
            'is-success': valid,
            'is-danger': errors[0],
            'is-expanded': expanded,
            'w-9/10': addon == true
          }"
          ref="input"
          :allow-negative="false"
          :disabled="disabled"
        />
      </div>
      <currency-input
        v-else
        :id="idinput"
        :placeholder="placeholder"
        v-model="innerValue"
        currency="USD"
        locale="en"
        class="input"
        :class="{
          'is-success': valid,
          'is-danger': errors[0],
          'is-expanded': expanded,
          'w-9/10': addon == true
        }"
        ref="input"
        :allow-negative="false"
        :disabled="disabled"
      />
      <b-select
        class="w-1/3"
        placeholder="Selecciona una opción"
        v-if="select"
        v-model="optionSelected"
        @input="$emit('select', optionSelected)"
      >
        <option v-for="opcion in select" :key="opcion">{{ opcion }}</option>
      </b-select>
      <p class="control w-1/10" v-if="addon">
        <span class="button is-static has-background-grey-lighter">
          <fa-icon :icon="addonIcon" size="lg" />
        </span>
      </p>
    </b-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "validInput",
  props: {
    select: [Array, null],
    rules: String,
    label: String,
    placeholder: String,
    type: String,
    icon: String,
    inputclass: String,
    idinput: String,
    value: null,
    expanded: {
      type: Boolean,
      default: false
    },
    addon: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    labelClass: String,
    addonIcon: String
  },
  components: {
    ValidationProvider
  },
  data: () => ({
    innerValue: 0,
    optionSelected: null
  }),
  watch: {
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>
