<template>
  <div class="text-left">
    <p class="font-bold text-lg text-left mb-4">
      Cantidad a castigar
    </p>
    <div v-if="getCastigo != null && getCastigo.length > 0">
      <b-message type="is-warning" class="mb-4">
        Esta disposición fue castigada el día
        {{ getCastigo[0].fecha_castigo | moment("DD-MM-YYYY") }} por el empleado
        {{ getCastigo[0].empleado }} por la cantidad de
        {{ getCastigo[0].monto_castigo | currency }}
      </b-message>
    </div>
    <div class="has-border-radius border-2 p-5" v-else>
      <validation-observer
        ref="observer"
        v-slot="{ validate }"
        class="w-full text-left"
      >
        <valid-currency-input
          rules="required|currency"
          label="Cantidad a castigar"
          placeholder="0.00"
          v-model="innerValue"
        />
        <valid-input
          rules="required"
          label="Notas"
          placeholder="Notas..."
          v-model="notas"
          type="textarea"
          :expanded="true"
        />
        <b-button
          expanded
          class="mt-4"
          :type="pasivo ? 'is-pasivo' : 'is-primary'"
          @click="validate().then(res => setCastigo(res))"
          >{{ btnTitle }}</b-button
        >
      </validation-observer>
    </div>
  </div>
</template>

<script>
import ValidCurrencyInput from "@/components/form/ValidCurrencyInput";
import ValidInput from "@/components/form/ValidInput";
import { ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";

export default {
  name: "Castigo",
  data() {
    return {
      innerValue: null,
      notas: null
    };
  },
  props: {
    value: Number,
    pasivo: {
      type: Boolean,
      default: false
    },
    id: Number
  },
  computed: {
    btnTitle() {
      return "Aplicar Castigo";
    },
    ...mapGetters(["getFechaCierre"]),
    ...mapGetters("disposicion", ["getCastigo"])
  },
  components: {
    ValidCurrencyInput,
    ValidInput,
    ValidationObserver
  },
  methods: {
    //Función que crea un castigo en API
    setCastigo(res) {
      if (!res) return;
      this.$store.dispatch("disposicion/setCastigo", {
        id: this.id,
        monto_castigo: this.innerValue,
        notas: this.notas,
        fecha_castigo: this.$moment(this.getFechaCierre).format(
          "YYYY-MM-DD HH:mm:ss"
        )
      });
    }
  },
  watch: {
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
    this.$store.dispatch("disposicion/getCastigos", this.id);
  }
};
</script>
