<template>
  <div class="modal-card">
    <section class="modal-card-head pb-0">
      <p class="font-bold text-2xl">Desglose de saldos</p>
      <button
        type="button"
        class="delete absolute right-0 mr-5"
        @click="$parent.close()"
      />
    </section>
    <section class="modal-card-body has-background-white text-left">
      <b-table :data="infoDetail" :sticky-header="true" >
        <b-table-column class="pl-0 text-sm capitalize"
          field="row" label="Saldo" v-slot="props">
          {{ props.row[0].split("_").join(" ") }}
        </b-table-column>
        <b-table-column
          class="pl-0 text-right"
          v-slot="props"
          label="Valor"
          field="row"
        >
          <span :class="{ 'has-text-primary font-bold':props.row[1] > 0 }">
            {{ props.row[1] | currency }}
          </span>
        </b-table-column>
      </b-table>
    </section>
    <section class="modal-card-foot justify-end"></section>
  </div>
</template>

<script>
export default {
  name: "ModalDetalleSaldos",
  props: {
    saldos: Object
  },
  computed: {
    infoDetail() {
      return Object.entries(this.$props.saldos);
    }
  }
};
</script>
