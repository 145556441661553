<template>
  <div class="card has-border-radius has-background-white-ter shadow-none p-4">
    <div
      class="h-2 has-border-radius w-full"
      :class="{
        'progress-gradient': infoDocumento.pasivo == 0,
        'progress-gradient-pasivo': infoDocumento.pasivo
      }"
    />
    <p class="text-xl font-bold text-left mb-3 mt-1">Resumen del Pago</p>
    <div class="border mb-3" />
    <div>
      <p class="text-left has-text-grey">{{ infoDocumento.persona_id ? 'Acreditado' : 'Grupo' }}</p>
      <p v-if="infoDocumento.persona_id" class="text-left">
        <preview
          tipo="persona"
          :color="
            infoDocumento.pasivo == 0 ? 'has-text-primary' : 'has-text-pasivo'
          "
          :border="
            infoDocumento.pasivo == 0
              ? 'has-border-primary'
              : 'has-border-pasivo'
          "
          :id="infoDocumento.persona_id"
          >{{ infoDocumento.nombre }}</preview
        >
      </p>
      <p v-if="!infoDocumento.persona_id && infoDocumento.grupo">
        {{ infoDocumento.grupo }}
      </p>
    </div>
    <div class="my-2" v-if="getDisposicion && getDisposicion.lineas_credito">
      <p class="text-left has-text-grey">Línea de crédito</p>
      <p class="text-left">
        <preview
          class="text-left"
          :id="getDisposicion.lineas_credito[0].id"
          tipo="linea"
          :color="
            getDisposicion.pasivo == 0 ? 'has-text-primary' : 'has-text-pasivo'
          "
          :border="
            getDisposicion.pasivo == 0
              ? 'has-border-primary'
              : 'has-border-pasivo'
          "
          >{{ "#" + getDisposicion.lineas_credito[0].id }}</preview
        >
      </p>
    </div>
    <div class="my-2" v-if="getDisposicion && getDisposicion.credito_status">
      <p class="text-left has-text-grey">Estatus de la disposición</p>
      <p class="text-left">{{ getDisposicion.credito_status.nombre }}</p>
    </div>
    <div
      class="my-1"
      v-if="getDisposicion && getDisposicion.producto_financiero"
    >
      <p class="text-left has-text-grey">Producto Financiero</p>
      <p class="text-left">{{ getDisposicion.producto_financiero.alias }}</p>
    </div>
    <div class="border my-2" />
    <p class="text-left text-lg font-bold mt-2" v-if="tipo == 'disposicion'">
      Desglose del pago
    </p>
    <div class="my-1" v-if="tipo == 'disposicion'">
      <p class="text-left has-text-grey">Monto total a pagar</p>
      <p class="text-left">{{ montoTotal | currency }}</p>
    </div>
    <div>
      <div v-if="getSimulacion != null && config.monto > 0" class="mb-6">
        <div
          class="columns mt-1 is-multiline"
          v-for="(saldo, key) in getSimulacion.saldos_despues_pago"
          :key="saldo.tipos_saldo_id"
        >
          <div class="column py-0 is-6 text-left">
            <p class="font-medium">{{ saldo.tipo_saldo.nombre }}</p>
          </div>
          <div class="column py-0 is-6 text-right">
            {{ saldo.valor | currency }}
          </div>
          <div
            class="columns column is-12 pb-0 pr-0"
            v-if="
              Array.isArray(getSimulacion.saldos_especiales) &&
                getSimulacion.saldos_especiales.find(
                  x => x.tipos_saldo_id == key && x.condonado == 1
                )
            "
          >
            <div class="column is-6 text-left py-0">
              <p class="text-sm font-light">condonado</p>
            </div>
            <div class="column is-6 text-right py-0 pr-0">
              <p class="text-sm font-light has-text-danger text-right">
                {{
                  getSimulacion.saldos_especiales.find(
                    x => x.tipos_saldo_id == key && x.condonado == 1
                  ).valor | currency
                }}
              </p>
            </div>
          </div>
          <div
            class="columns column is-12 py-0 pr-0"
            v-if="
              Array.isArray(getSimulacion.saldos_especiales) &&
                getSimulacion.saldos_especiales.find(
                  x => x.tipos_saldo_id == key && x.con_quita == 1
                )
            "
          >
            <div class="column is-6 text-left py-0">
              <p class="text-sm font-light">quita</p>
            </div>
            <div class="column is-6 text-right py-0 pr-0">
              <p class="text-sm font-light has-text-danger text-right">
                {{
                  getSimulacion.saldos_especiales.find(
                    x => x.tipos_saldo_id == key && x.con_quita == 1
                  ).valor | currency
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="border my-2" v-if="tipo == 'disposicion'" />
      <p class="text-left text-lg font-bold mt-2">Datos generales</p>
      <div class="my-1" v-if="getGarantia">
        <p class="text-left has-text-grey">Tipo de garantía</p>
        <p class="text-left">
          {{ getGarantia.garantia.tipo_garantia.nombre }}
        </p>
      </div>
      <div class="my-1">
        <p class="text-left has-text-grey">Moneda</p>
        <p class="text-left">
          {{
            getMonedas.length > 0 && config.moneda != null
              ? getMonedas.find(x => x.id == config.moneda).nombre
              : "No definida"
          }}
        </p>
      </div>
      <div class="my-1">
        <p class="text-left has-text-grey">Instrumento monetario</p>
        <p class="text-left">
          {{
            getInstrumentosMonetarios != null &&
            config.instrumento_monetario != null
              ? getInstrumentosMonetarios.find(
                  x => x.id == config.instrumento_monetario
                ).nombre
              : "No definido"
          }}
        </p>
      </div>
      <div class="my-1">
        <p class="text-left has-text-grey">Cuenta de banco</p>
        <p class="text-left">{{ config.cuenta_banco || "No definida" }}</p>
      </div>
    </div>
    <!-- ACCESORIOS -->
    <div v-if="accesorios.length > 0">
      <div class="border" />
      <p class="text-left text-lg font-bold mt-2">Accesorios</p>
      <div
        class="card shadow-none my-2 p-2 has-border-radius has-background-grey-lighter"
        v-for="accesorio in accesorios"
        :key="accesorio.id"
      >
        <div class="columns text-left">
          <div class="column text-sm">
            <p class="has-text-grey-light">Accesorio</p>
            <p>{{ accesorio.tipo_de_operacion.nombre }}</p>
          </div>
          <div class="column text-sm">
            <p class="has-text-grey-light">Monto</p>
            <p>{{ accesorio.monto_pagar | currency }}</p>
          </div>
          <div class="column text-sm">
            <p class="has-text-grey-light">IVA</p>
            <p>{{ accesorio.iva | currency }}</p>
          </div>
          <div class="column text-sm">
            <p class="has-text-grey-light">Pago</p>
            <p>{{ accesorio.monto_pagar | currency }}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="pagosEspeciales">
      <!-- Condonaciones -->
      <div v-if="condonaciones.length > 0">
        <div class="border" />
        <p class="text-left text-lg font-bold mt-2">Condonaciones</p>
        <div
          class="card shadow-none my-2 p-2 has-border-radius has-background-grey-lighter"
          v-for="condonacion in condonaciones"
          :key="condonacion.id"
        >
          <div class="columns text-left">
            <div class="column text-sm">
              <p class="has-text-grey-light">Tipo de saldo</p>
              <p>
                {{
                  getTiposSaldo.find(x => x.id == condonacion.tipo_saldo_id)
                    .nombre
                }}
              </p>
            </div>
            <div class="column text-sm">
              <p class="has-text-grey-light">Monto</p>
              <p>{{ condonacion.valor | currency | hp }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Quitas -->
      <div v-if="quitas.length > 0">
        <div class="border" />
        <p class="text-left text-lg font-bold mt-2">Quitas</p>
        <div
          class="card shadow-none my-2 p-2 has-border-radius has-background-grey-lighter"
          v-for="quita in quitas"
          :key="quita.id"
        >
          <div class="columns text-left">
            <div class="column text-sm">
              <p class="has-text-grey-light">Tipo de saldo</p>
              <p>
                {{
                  getTiposSaldo.find(x => x.id == quita.tipo_saldo_id).nombre
                }}
              </p>
            </div>
            <div class="column text-sm">
              <p class="has-text-grey-light">Monto</p>
              <p>{{ quita.valor | currency | hp }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Castigo -->
      <div v-if="castigo" class="text-left">
        <div class="border" />
        <p class="has-text-danger font-bold text-lg mt-2">Castigo</p>
        <div
          class="has-border-radius has-background-danger-light card p-3 shadow-none"
        >
          <div class="columns">
            <div class="column">Cantidad</div>
            <div class="column text-right">
              <p class="has-text-danger font-bold">{{ castigo | currency }}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="border my-2"
        v-if="
          orderChanged ||
            condonaciones.length > 0 ||
            quitas.length ||
            castigo != null
        "
      />
      <ul class="text-left list-disc ml-4">
        <li v-if="orderChanged" class="has-text-danger">
          El orden de la prelación ha cambiado
        </li>
        <li v-if="condonaciones.length > 0" class="has-text-danger">
          Existen condonaciones en este pago
        </li>
        <li v-if="quitas.length > 0" class="has-text-danger">
          Existen quitas en este pago
        </li>
        <li v-if="castigo != null" class="has-text-danger">
          La disposición será castigada
        </li>
      </ul>
    </div>
    <div class="border my-3" />
    <div class="columns is-vcentered">
      <div class="column text-left">
        Monto a
        {{
          $route.query ? ($route.query.pago == 1 ? "pagar" : "cobrar") : "pagar"
        }}
      </div>
      <div class="column text-right">
        <p
          class="text-xl font-medium"
          :class="{
            'has-text-primary': infoDocumento.pasivo == 0,
            'has-text-pasivo': infoDocumento.pasivo,
            'has-text-danger': montoTotal < 0
          }"
        >
          {{ montoTotal | currency | hp }}
        </p>
        <p>
          {{
            getMonedas.length > 0 && config.moneda != null
              ? getMonedas.find(x => x.id == config.moneda).nombre
              : ""
          }}
        </p>
      </div>
    </div>
    <b-button
      :type="infoDocumento.pasivo == 1 ? 'is-pasivo' : 'is-primary'"
      :disabled="this.loading"
      expanded
      @click="validate().then(res => pagar(res))"
    >
      Aplicar
      {{ $route.query ? ($route.query.pago && $route.query.pago.split('?tk=')[0] == 1 ? "pago" : "cobro") : "pago" }}
    </b-button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ResumenPago",
  methods: {
    /*
      Función que hace la llamada a la API para pagar
      @res (Boolean) resultado del validationobserveer
    */
    pagar(res) {
      if (!res) return;
      let data = {
        empleados_id: this.getMe.empleado.id,
        sucursal_id: this.getMe.sucursal_activa.id,
        moneda_id: this.config.moneda,
        instrumentoMonetario_id: this.config.instrumento_monetario,
        cantidad_pagada: this.config.monto,
        tipo_cambio_pactado: this.config.tipo_cambio_pactado,
        doc_id: this.id
      };
      if (this.config.cuenta_banco) {
        data.CuentasBanco_id = this.getCuentasBancarias.find(
          x => x.cuenta == this.config.cuenta_banco
        ).id;
      }
      if (this.config.persona_cuenta_banco && this.config.persona_cuenta_banco !== "Otra Cuenta") {
        data.persona_CuentasBanco_id = this.getPersonaCuentasBancarias.find(
          x => x.cuenta == this.config.persona_cuenta_banco
        ).id;
      }
      switch (this.tipo) {
        case "disposicion":
          if (this.montoTotal < 0) {
            this.notification(
              "warning",
              "El monto tiene que ser mayor a 0",
              "El monto es menor a 0 debido a las condonaciones / quitas"
            );
            return;
          }
          data.condonaciones = this.condonaciones;
          data.quitas = this.quitas;
          data.orden_prelacion = this.tiposSaldo;
          if (this.pagosEspeciales) {
            this.loading = true;
            this.$store
              .dispatch("pagos/pagarDisposicionEspecial", data)
              .then(data => {
                this.loading = false;
                if (data.id) {
                  this.$router.push({
                    name: "detallepagoroot",
                    params: {
                      idPago: data.id
                    }
                  });
                }
              });
          } else {
            this.loading = true;
            this.$store.dispatch("pagos/pagarDisposicion", data).then(data => {
              this.loading = false;
              if (data.id) {
                this.$router.push({
                  name: "detallepagoroot",
                  params: {
                    idPago: data.id
                  }
                });
              }
            });
          }
          break;
        case "cuentaCP":

          if (this.montoTotal > this.getCuentaCP.saldo) {
            this.notification(
              "warning",
              "El monto sobrepasa el límite",
              "El monto a pagar es mayor que el saldo total de la cuenta"
            );
            return;
          }

          if (this.getCuentaCP.simulaciones.length > 0) {
            data.creditos_core_id = this.getCuentaCP.simulaciones[0].id;
          }
          this.loading = true;
          this.$store.dispatch("pagos/pagarCuentaCP", data).then(data => {
            this.loading = false;
            this.$router.push({
              name: "detallepagoroot",
              params: {
                idPago: data.id
              }
            });
          });
          break;
        case "garantia":
          data.devolucion = this.config.devolucion;
          data.iva = this.config.iva;
          data.interes = this.config.interes;
          data.capital = data.cantidad_pagada;
          if(this.getPersonas != null && this.getPersonas.length > 0){
            data.personas_id = this.getPersonas[0].id;
          }
          if (this.montoTotal > this.getGarantia.saldo_capital) {
            this.notification(
              "warning",
              "El monto sobrepasa el límite",
              "El monto a pagar es mayor que la deuda al día de hoy"
            );
            return;
          }
          if (this.$route.query.pago.split('?tk=')[0] == 1) {
            this.loading = true;
            this.$store
              .dispatch("pagos/pagarGarantiaPasivo", data)
              .then(data => {
                this.loading = false;
                if (data.id) {
                  this.$router.push({
                    name: "detallepagoroot",
                    params: {
                      idPago: data.id
                    }
                  });
                }
              });
          } else {
            this.loading = true;
            this.$store
              .dispatch("pagos/cobrarGarantiaActivo", data)
              .then(data => {
                this.loading = false;
                if (data.id) {
                  this.$router.push({
                    name: "detallepagoroot",
                    params: {
                      idPago: data.id
                    }
                  });
                }
              });
          }
          break;
      }
    }
  },
  props: {
    config: Object,
    accesorios: Array,
    pagosEspeciales: Boolean,
    condonaciones: Array,
    quitas: Array,
    castigo: [Number, null],
    orderChanged: Boolean,
    tiposSaldo: Array,
    validate: Function,
    id: Number,
    tipo: String,
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("catalogos", [
      "getInstrumentosMonetarios",
      "getMonedas",
      "getTiposSaldo"
    ]),
    ...mapGetters("empresa", ["getCuentasBancarias"]),
    ...mapGetters("personas", ["getPersonaCuentasBancarias"]),
    ...mapGetters("pagos", [
      "getDisposicion",
      "getSimulacion",
      "getCuentaCP",
      "getGarantia"
    ]),
    ...mapGetters(["getMe"]),
    ...mapGetters("garantias", ["getPersonas"]),
    montoTotal() {
      return (
        this.config.monto +
        this.config.interes +
        this.config.iva +
        this.config.devolucion
      );
    },
    infoDocumento() {
      switch (this.tipo) {
        case "disposicion":
          return {
            pasivo: this.getDisposicion.pasivo,
            persona_id: this.getDisposicion.cliente.id,
            nombre: this.getDisposicion.cliente.nombre_completo.split("|")[0]
          };
        case "cuentaCP":
          return {
            pasivo: !this.getCuentaCP.cobro,
            persona_id: this.getCuentaCP.acreditado?.id,
            nombre: this.getCuentaCP.acreditado?.nombre_completo.split("|")[0],
            grupo: this.getCuentaCP.grupo_credito 
              ? `${this.getCuentaCP.grupo_credito.nombre} - ${this.getCuentaCP.grupo_credito.sucursal.nombre}` 
              : null
          };
        case "garantia":
          return {
            pasivo: 0,
            persona_id:
              this.getPersonas != null && this.getPersonas.length > 0
                ? this.getPersonas[0].id
                : 0,
            nombre:
              this.getPersonas != null && this.getPersonas.length > 0
                ? this.getPersonas[0].nombre_completo.split("|")[0]
                : 0
          };
        default:
          return {
            pasivo: 0
          };
      }
    }
  }
};
</script>
