<template>
  <div class="columns is-multiline pb-4">
    <div class="column" v-if="!pago">
      <p class="text-left text-2xl font-medium">
        Orden de prelación global
      </p>
    </div>
    <div class="column text-right" v-if="!pago">
      <b-button @click="setOrdenPrelacionSaldos" type="is-primary"
        >Guardar Orden Global</b-button
      >
    </div>
    <div class="column is-12 text-left">
      <b-tag
        :type="tag.type"
        v-for="tag in tags"
        :key="tag.label"
        class="cursor-pointer mx-2 px-20 py-4 text-md hover:opacity-75 transition ease-in duration-200"
        @click.native="setActiveTag(tag)"
        >{{ tag.label }}</b-tag
      >
    </div>
    <div class="column is-12">
      <draggable
        :list="tipos_saldo"
        class="list-group"
        group="saldos"
        ghost-class="ghost"
        @start="dragging = true"
        @end="dragging = false"
      >
        <transition-group class="w-full" name="fadeInOut" mode="out-in">
          <div
            class="card m-1 has-border-radius shadow-none p-1 list-group-item cursor-move card-saldos"
            :class="color(saldo.nombre).bg"
            v-for="(saldo, index) in tipos_saldo"
            :key="saldo.id"
          >
            <div class="columns">
              <div class="column text-center is-1">
                <p class="font-bold">{{ index + 1 }}°</p>
              </div>
              <div class="column text-left">
                <p :class="color(saldo.nombre).text">{{ saldo.nombre }}</p>
              </div>
              <div class="column text-right md:pr-8">
                <b-icon
                  v-if="index > 0"
                  @click.native="goUp(index)"
                  :class="{ 'mr-6': index == tipos_saldo.length - 1 }"
                  icon="chevron-up"
                  class="cursor-pointer"
                />
                <b-icon
                  v-if="index < tipos_saldo.length - 1"
                  @click.native="goDown(index)"
                  icon="chevron-down"
                  class="cursor-pointer"
                />
              </div>
            </div>
          </div>
        </transition-group>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapGetters } from "vuex";

export default {
  name: "OrdenPrelacion",
  components: {
    draggable
  },
  props: {
    pago: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tipos_saldo: [],
      dragging: false,
      tags: [
        {
          label: "IVA",
          type: "is-light"
        },
        {
          label: "Capital",
          type: "is-light"
        },
        {
          label: "Interés",
          type: "is-light"
        }
      ]
    };
  },
  computed: {
    ...mapGetters("catalogos", ["getTiposSaldo"])
  },
  watch: {
    tipos_saldo(val) {
      if (this.pago) {
        let saldos_ordenados = val.map(saldo => {
          return saldo.id;
        });
        this.$emit("ordered", saldos_ordenados);
      }
    }
  },
  methods: {
    /*
      Función para subir un saldo en el orden de prelación
      @index (Number) indice del saldo a subir
    */
    goUp(index) {
      this.dragging = true;
      let saldo_temp = JSON.parse(JSON.stringify(this.tipos_saldo[index - 1]));
      this.$set(this.tipos_saldo, index - 1, this.tipos_saldo[index]);
      this.$set(this.tipos_saldo, index, saldo_temp);
      this.dragging = false;
    },
    /*
      Función para bajar un saldo en el orden de prelación
      @index (Number) indice del saldo a bajaar
    */
    goDown(index) {
      this.dragging = true;
      let saldo_temp = JSON.parse(JSON.stringify(this.tipos_saldo[index + 1]));
      this.$set(this.tipos_saldo, index + 1, this.tipos_saldo[index]);
      this.$set(this.tipos_saldo, index, saldo_temp);
      this.dragging = false;
    },
    /*
      Función para enviar a API el nuevo orden de prelación
    */
    setOrdenPrelacionSaldos() {
      let saldos_ordenados = this.tipos_saldo.map(saldo => {
        return saldo.id;
      });
      this.$store
        .dispatch("catalogos/setOrdenPrelacionSaldos", saldos_ordenados)
        .then(data => {
          this.tipos_saldo = data;
        });
    },
    /*
      Función que se encarga de indicar el color dependiendo del tipo de saldo
      @nombre (String) nombre del tipo de saldo
    */
    color(nombre) {
      if (nombre.includes("IVA") && this.tags[0].type == "is-link") {
        return {
          bg: "has-background-info-light",
          text: "has-text-info"
        };
      }
      if (
        nombre.includes("Capital") &&
        !nombre.includes("IVA") &&
        this.tags[1].type == "is-pasivo"
      ) {
        return {
          bg: "has-background-pasivo-04",
          text: "has-text-pasivo"
        };
      }
      if (
        nombre.includes("Interes") &&
        !nombre.includes("IVA") &&
        this.tags[2].type == "is-danger"
      ) {
        return {
          bg: "has-background-danger-light",
          text: "has-text-danger"
        };
      }
      return {
        bg: "has-background-white-ter",
        text: "has-text-grey-darker"
      };
    },
    /*
      Función que se encarga de activar/desactivar las tags
      @tag (Object) objeto del array tags que esta en data
    */
    setActiveTag(tag) {
      switch (tag.label) {
        case "IVA":
          tag.type = tag.type == "is-light" ? "is-link" : "is-light";
          break;
        case "Capital":
          tag.type = tag.type == "is-light" ? "is-pasivo" : "is-light";
          break;
        case "Interés":
          tag.type = tag.type == "is-light" ? "is-danger" : "is-light";
          break;
      }
    }
  },
  mounted() {
    if (this.getTiposSaldo == null) {
      this.$store.dispatch("catalogos/getTiposSaldo").then(data => {
        this.tipos_saldo = JSON.parse(JSON.stringify(data));
      });
    } else {
      this.tipos_saldo = JSON.parse(JSON.stringify(this.getTiposSaldo));
    }
  }
};
</script>

<style lang="scss">
@import "@/scss/_variables.scss";
.card-saldos {
  transition: all 0.15s ease-in-out;
  &:hover {
    background-color: $white-bis !important;
  }
}
</style>
