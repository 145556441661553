var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"rules":_vm.rules == 'none' ? '' : _vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{class:{ 'flex-row-reverse': _vm.select },attrs:{"label":_vm.label,"message":errors,"custom-class":_vm.labelClass,"type":{ 'is-danger': errors[0] }}},[(_vm.select)?_c('div',{staticClass:"control is-clearfix w-2/3"},[_c('currency-input',{ref:"input",staticClass:"input",class:{
          'is-success': valid,
          'is-danger': errors[0],
          'is-expanded': _vm.expanded,
          'w-9/10': _vm.addon == true
        },attrs:{"id":_vm.idinput,"placeholder":_vm.placeholder,"currency":"USD","locale":"en","allow-negative":false,"disabled":_vm.disabled},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}})],1):_c('currency-input',{ref:"input",staticClass:"input",class:{
        'is-success': valid,
        'is-danger': errors[0],
        'is-expanded': _vm.expanded,
        'w-9/10': _vm.addon == true
      },attrs:{"id":_vm.idinput,"placeholder":_vm.placeholder,"currency":"USD","locale":"en","allow-negative":false,"disabled":_vm.disabled},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}}),(_vm.select)?_c('b-select',{staticClass:"w-1/3",attrs:{"placeholder":"Selecciona una opción"},on:{"input":function($event){return _vm.$emit('select', _vm.optionSelected)}},model:{value:(_vm.optionSelected),callback:function ($$v) {_vm.optionSelected=$$v},expression:"optionSelected"}},_vm._l((_vm.select),function(opcion){return _c('option',{key:opcion},[_vm._v(_vm._s(opcion))])}),0):_vm._e(),(_vm.addon)?_c('p',{staticClass:"control w-1/10"},[_c('span',{staticClass:"button is-static has-background-grey-lighter"},[_c('fa-icon',{attrs:{"icon":_vm.addonIcon,"size":"lg"}})],1)]):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }