<template>
  <div class="text-right">
    <b-table :data="filas">
      <b-table-column class="pl-0 text-sm" label="Concepto" v-slot="props">
        <span class="font-bold">{{ props.row.nombre }}</span>
      </b-table-column>
      <b-table-column class="text-right text-sm" label="Vigente" v-slot="props">
        <div v-if="typeof props.row.vigente === 'number'">
          {{ props.row.vigente | currency }}
        </div>
        <div v-else>--</div>
      </b-table-column>
      <b-table-column class="text-right text-sm" label="IVA" v-slot="props">
        <div v-if="typeof props.row.vigente_iva === 'number'">
          {{ props.row.vigente_iva | currency }}
        </div>
        <div v-else>--</div>
      </b-table-column>
      <b-table-column class="text-right text-sm" label="Impago" v-slot="props">
        <div v-if="typeof props.row.impago === 'number'">
          {{ props.row.impago | currency }}
        </div>
        <div v-else>--</div>
      </b-table-column>
      <b-table-column class="text-right text-sm" label="IVA" v-slot="props">
        <div v-if="typeof props.row.impago_iva === 'number'">
          {{ props.row.impago_iva | currency }}
        </div>
        <div v-else>--</div>
      </b-table-column>
      <b-table-column class="text-right text-sm" label="Vencido exigible" v-slot="props">
        <div v-if="typeof props.row.vencido === 'number'">
          {{ props.row.vencido | currency }}
        </div>
        <div v-else>--</div>
      </b-table-column>
      <b-table-column class="text-right text-sm" label="IVA" v-slot="props">
        <div v-if="typeof props.row.vencido_iva === 'number'">
          {{ props.row.vencido_iva | currency }}
        </div>
        <div v-else>--</div>
      </b-table-column>
      <b-table-column class="text-right text-sm" label="Vencido no exigible" v-slot="props">
        <div v-if="typeof props.row.vencidono === 'number'">
          {{ props.row.vencidono | currency }}
        </div>
        <div v-else>--</div>
      </b-table-column>
      <b-table-column class="text-right text-sm" label="IVA" v-slot="props">
        <div v-if="typeof props.row.vencidono_iva === 'number'">
          {{ props.row.vencidono_iva | currency }}
        </div>
        <div v-else>--</div>
      </b-table-column>
      <b-table-column
        class="text-right text-sm"
        v-if="columns.find(x => x.label == 'Condonado')"
        v-slot="props"
      >
        <div
          v-if="
            typeof props.row.condonado === 'number' && props.row.condonado > 0
          "
          class="has-text-danger"
        >
          {{ props.row.condonado | currency }}
        </div>
        <div v-else>--</div>
      </b-table-column>
      <b-table-column
        class="text-right text-sm"
        v-if="columns.find(x => x.label == 'Quita')"
        v-slot="props"
      >
        <div
          v-if="typeof props.row.quita === 'number' && props.row.quita > 0"
          class="has-text-danger"
        >
          {{ props.row.quita | currency }}
        </div>
        <div v-else>--</div>
      </b-table-column>
    </b-table>
    <b-button size="is-small" type="is-text" @click="is_modal_open = true"
      >Ver detalle completo</b-button
    >
    <portal to="modalContainer">
      <b-modal
        v-if="is_modal_open"
        :active.sync="is_modal_open"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
      >
        <modal-detalle-saldos :saldos="desglose" />
      </b-modal>
    </portal>
  </div>
</template>

<script>
import ModalDetalleSaldos from "@/components/modals/ModalDetalleSaldos";

export default {
  name: "SaldoTable",
  props: {
    desglose: Object
  },
  components: {
    ModalDetalleSaldos
  },
  data() {
    return {
      is_modal_open: false
    };
  },
  computed: {
    columns() {
      let col = [
        { label: "Concepto", headerClass: "pl-0 text-sm", field: 'nombre'},
        { label: "Vigente", headerClass: "text-right text-sm", field: 'vigente' },
        { label: "IVA", headerClass: "text-right text-sm", field: 'vigente_iva'},
        { label: "Impago", headerClass: "text-right text-sm", field: 'impago' },
        { label: "IVA", headerClass: "text-right text-sm", field: 'impago_iva' },
        { label: "Vencido exigible", headerClass: "text-right text-sm", field: 'vencido' },
        { label: "IVA", headerClass: "text-right text-sm", field: 'vencido_iva' },
        { label: "Vencido no exigible", headerClass: "text-right text-sm", field: 'vencidono' },
        { label: "IVA", headerClass: "text-right text-sm", field: 'vencidono_iva'}
      ];
      if (this.desglose) {
        let condonado = 0;
        let quita = 0;
        Object.keys(this.desglose).forEach(x => {
          if (x.includes("_condonado")) {
            condonado++;
          }
          if (x.includes("_quita")) {
            quita++;
          }
        });
        if (condonado > 0) {
          col.push({ label: "Condonado", headerClass: "text-right text-sm", field:'condonado' });
        }
        if (quita > 0) {
          col.push({ label: "Quita", headerClass: "text-right text-sm", field:'quita' });
        }
      }
      return col;
    },
    filas() {
      return [
        {
          nombre: "Capital",
          vigente: this.desglose.capital_vigente,
          vigente_iva: this.desglose.iva_capital_vigente,
          impago: this.desglose.capital_vencido_por_traspasar,
          impago_iva: this.desglose.iva_capital_vencido_por_traspasar,
          vencido: this.desglose.capital_vencido_exigible,
          vencido_iva: this.desglose.iva_capital_vencido_exigible,
          vencidono: this.desglose.capital_vencido_no_exigible,
          vencidono_iva: this.desglose.iva_capital_vencido_no_exigible,
          condonado:
            this.desglose.capital_vigente_condonado ||
            0 + this.desglose.iva_capital_vigente_condonado ||
            0 + this.desglose.capital_vencido_por_traspasar_condonado ||
            0 + this.desglose.capital_vencido_exigible_condonado ||
            0 + this.desglose.iva_capital_vencido_exigible_condonado ||
            0 + this.desglose.capital_vencido_no_exigible_condonado ||
            0 + this.desglose.iva_capital_vencido_no_exigible_condonado ||
            0,
          quita:
            this.desglose.capital_vigente_quita ||
            0 + this.desglose.iva_capital_vigente_quita ||
            0 + this.desglose.capital_vencido_por_traspasar_quita ||
            0 + this.desglose.capital_vencido_exigible_quita ||
            0 + this.desglose.iva_capital_vencido_exigible_quita ||
            0 + this.desglose.capital_vencido_no_exigible_quita ||
            0 + this.desglose.iva_capital_vencido_no_exigible_quita ||
            0
        },
        {
          nombre: "Interés ordinario",
          vigente: this.desglose.interes_ordinario_vigente,
          vigente_iva: this.desglose.iva_interes_ordinario_vigente,
          impago: this.desglose.interes_ordinario_vencido_por_traspasar,
          impago_iva: this.desglose.iva_interes_ordinario_vencido_por_traspasar,
          vencido: this.desglose.interes_ordinario_vencido_exigible,
          vencido_iva: this.desglose.iva_interes_ordinario_vencido_exigible,
          vencidono: this.desglose.interes_ordinario_vencido_no_exigible,
          vencidono_iva: this.desglose
            .iva_interes_ordinario_vencido_no_exigible,
          condonado:
            this.desglose.interes_ordinario_condonado ||
            0 + this.desglose.iva_interes_ordinario_condonado ||
            0 +
              this.desglose.interes_ordinario_vencido_por_traspasar_condonado ||
            0 + this.desglose.interes_ordinario_vencido_exigible_condonado ||
            0 +
              this.desglose.iva_interes_ordinario_vencido_exigible_condonado ||
            0 + this.desglose.interes_ordinario_vencido_no_exigible_condonado ||
            0 +
              this.desglose
                .iva_interes_ordinario_vencido_no_exigible_condonado ||
            0,
          quita:
            this.desglose.interes_ordinario_quita ||
            0 + this.desglose.iva_interes_ordinario_quita ||
            0 + this.desglose.interes_ordinario_vencido_por_traspasar_quita ||
            0 + this.desglose.interes_ordinario_vencido_exigible_quita ||
            0 + this.desglose.iva_interes_ordinario_vencido_exigible_quita ||
            0 + this.desglose.interes_ordinario_vencido_no_exigible_quita ||
            0 + this.desglose.iva_interes_ordinario_vencido_no_exigible_quita ||
            0
        },
        {
          nombre: "Interés refinanciado",
          vigente: this.desglose.interes_refinanciado_vigente,
          // vigente_iva: "-",
          impago: this.desglose.interes_refinanciado_vencido_por_traspasar,
          // impago_iva: "-",
          vencido: this.desglose.interes_refinanciado_vencido_exigible,
          // vencido_iva: "-",
          vencidono: this.desglose.interes_refinanciado_vencido_no_exigible,
          // vencidono_iva: "-"
          condonado:
            this.desglose.interes_refinanciado_vigente_condonado ||
            0 +
              this.desglose
                .interes_refinanciado_vencido_por_traspasar_condonado ||
            0 + this.desglose.interes_refinanciado_vencido_exigible_condonado ||
            0 +
              this.desglose
                .interes_refinanciado_vencido_no_exigible_condonado ||
            0,
          quita:
            this.desglose.interes_refinanciado_vigente_quita ||
            0 +
              this.desglose.interes_refinanciado_vencido_por_traspasar_quita ||
            0 + this.desglose.interes_refinanciado_vencido_exigible_quita ||
            0 + this.desglose.interes_refinanciado_vencido_no_exigible_quita ||
            0
        },
        {
          nombre: "Interés moratorio cobrado",
          vencido: this.desglose.interes_moratorio + this.desglose.interes_moratorio_provisionado,
          vencido_iva: this.desglose.iva_interes_moratorio + this.desglose.iva_interes_moratorio_provisionado,
        },
        {
          nombre: "Interés moratorio condonado",
          vencido: (this.desglose.interes_moratorio_condonado || 0) + (this.desglose.iva_interes_moratorio_condonado || 0) +
            (this.desglose.interes_moratorio_provisionado_condonado || 0),
        }
      ];
    }
  }
};
</script>
