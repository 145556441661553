<template>
  <div>
    <p v-if="tipo == 'quita'" class="font-bold text-lg text-left mb-2">
      Agregar quitas
    </p>
    <p v-if="tipo == 'condonacion'" class="font-bold text-lg text-left mb-2">
      Condonar interés moratorio
    </p>
    <div class="border-2 has-border-radius p-5">
      <div class="columns is-multiline">
        <div class="column text-left is-6">
          <valid-select
            v-if="tipo == 'quita'"
            rules=""
            label="Tipo de Saldo"
            placeholder="Selecciona uno..."
            v-model="condonacion_tipo_saldo"
            fieldClass="has-text-grey-darker"
            :data="lista"
            show="tipo_saldo_nombre"
            :expanded="true"
            custom-show="valor"
            realValue="tipo_saldo_id"
          />
        </div>
        <div :class="{ 'is-12': tipo == 'condonacion', 'is-6': tipo == 'quita' }" class="column text-left">
          <valid-currency-input
            v-if="tipo == 'quita'"
            ref="monto"
            :rules="'currency|notcero|max_value:' + pagoMaximoQuitas"
            label="Cantidad"
            placeholder="0.00"
            v-model="condonacion_monto"
          />
          <valid-currency-input
          v-if="tipo == 'condonacion'"
            ref="monto"
            :rules="'currency|notcero|max_value:' + pagoMaximoCondonacion"
            :label="`Cantidad (max. $${pagoMaximoCondonacion})`"
            placeholder="0.00"
            v-model="condonacion_monto"
          />
        </div>
        <div class="column is-12">
          <b-button
            :type="pasivo ? 'is-pasivo' : 'is-primary'"
            expanded
            @click="addCondonacion"
            :disabled="
              condonacion_monto == null ||
              (tipo == 'condonacion' && condonaciones.length > 0) ||
              (tipo == 'quita' && condonacion_tipo_saldo == null)
            "
            >Agregar
            {{ tipo == "condonacion" ? "condonación" : "quita" }}</b-button
          >
        </div>
      </div>
    </div>
    <div class="columns is-multiline" v-if="condonaciones.length > 0">
      <div class="column is-12 pb-0">
        <p class="font-light my-3 text-lg">Condonaciones agregadas</p>
      </div>
      <div
        class="column is-12 pt-0"
        v-for="(condonacion, index) in condonaciones"
        :key="condonacion.tipo_saldo_id"
      >
        <div
          class="card has-border-radius p-2 shadow-none has-background-grey-lighter columns my-0 m-0"
        >
          <div class="column text-left">
            <p>Tipo de saldo</p>
            <span>
              {{
                lista.find(x => x.tipo_saldo_id == condonacion.tipo_saldo_id)
                  .tipo_saldo_nombre
              }}
            </span>
          </div>
          <div class="column text-left">
            <p>
              Monto
            </p>
            <span>{{ condonacion.valor | currency | hp }}</span>
          </div>
          <div class="column is-1 flex items-center justify-end">
            <b-dropdown
              aria-role="list"
              position="is-bottom-left"
              class="cursor-pointer"
            >
              <fa-icon icon="ellipsis-v" class="mr-4" slot="trigger" />
              <b-dropdown-item aria-role="listitem" @click="deleteRow(index)">
                Eliminar
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ValidSelect from "@/components/form/ValidSelect";
import ValidCurrencyInput from "@/components/form/ValidCurrencyInput";
import { mapGetters } from "vuex";

export default {
  name: "Condonaciones",
  data() {
    return {
      condonacion_tipo_saldo: null,
      condonacion_monto: null,
      condonaciones: []
    };
  },
  props: {
    value: Array,
    tipo: String,
    pasivo: {
      type: Boolean,
      default: false
    },
    lista: Array
  },
  components: {
    ValidSelect,
    ValidCurrencyInput
  },
  computed: {
    ...mapGetters("catalogos", ["getTiposSaldo"]),
    pagoMaximoQuitas() {
      let saldo = this.lista.find(
        x => x.tipo_saldo_id == this.condonacion_tipo_saldo
      );
      if (saldo) {
        return saldo.valor;
      }
      return 0;
    },
    pagoMaximoCondonacion() {
      let saldo = this.lista.reduce(
        (acc, curr) => acc + curr.valor,
        0
      );

      return saldo.toFixed(2);
    }
  },
  methods: {
    //Función para agregar condonación / quita al arreglo
    addCondonacion() {
      if (
        (this.tipo == "condonacion" && this.condonacion_monto != null) ||
        this.condonacion_tipo_saldo != null &&
        this.condonacion_monto != null
      ) {
        if (
          this.condonaciones.find(
            x => x.tipo_saldo_id == this.condonacion_tipo_saldo
          )
        ) {
          this.notification(
            "warning",
            "Condonación existente",
            `La condonación que deseas agregar ya existe en la lista de condonaciones`
          );
          return;
        }
        if (this.tipo == "condonacion") {

          this.lista.forEach(saldo => {

            if (this.condonacion_monto < 0.1) return;
            
            this.condonacion_tipo_saldo = saldo.tipo_saldo_id;

            const monto = this.condonacion_monto < saldo.valor ? this.condonacion_monto : saldo.valor;

            this.condonaciones.push({
              tipo_saldo_id: this.condonacion_tipo_saldo,
              valor: monto,
            });

            this.condonacion_monto -= monto;
          })

        } else {

          this.condonaciones.push({
            tipo_saldo_id: this.condonacion_tipo_saldo,
            valor: this.condonacion_monto
          });
        
        }
        this.condonacion_tipo_saldo = null;
        this.condonacion_monto = null;
      }
    },
    /*
      Función para eliminar una condonacion / quita en base a su indice en el arreglo
      @index (integer) indice del arreglo
    */
    deleteRow(index) {
      this.condonaciones.splice(index, 1);
    }
  },
  watch: {
    condonaciones(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.condonaciones = newVal;
    }
  },
  created() {
    if (this.value) {
      this.condonaciones = this.value;
    }
  }
};
</script>
